import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SkillCard from "./SkillCard.js";

import angular from "../assets/skills/angular.svg";
import javascript from "../assets/skills/javascript.svg";
import typescript from "../assets/skills/typescript.svg";
import css from "../assets/skills/css.svg";
import reactIcon from "../assets/skills/react.svg";
import nextjs from "../assets/skills/nextjs.svg";
import html from "../assets/skills/html.svg";
import azure from "../assets/skills/azure.svg";
import git from "../assets/skills/git.svg";
import github from "../assets/skills/github.svg";
import rxjs from "../assets/skills/rxjs.svg";
import redux from "../assets/skills/redux.svg";
import tailwindcss from "../assets/skills/tailwindcss.svg";
import jest from "../assets/skills/jest.svg";

export default function Skills() {
  return (
    <div className="mt-80 sm:mt-4 text-white">
      <h1 className="text-center text-5xl font-bold mb-16">Skills</h1>
      <div className="mt-4">
        <div class="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
          <SkillCard name="Javascript" experience="8 years" img={javascript} />
          <SkillCard name="Typescript" experience="8 years" img={typescript} />
          <SkillCard name="Html" experience="8 years" img={html} />
          <SkillCard name="Css" experience="8 years" img={css} />
          <SkillCard name="Angular" experience="8 years" img={angular} />
          <SkillCard name="React" experience="1 year" img={reactIcon} />
          <SkillCard name="NextJS" experience="1 year" img={nextjs} />
          <SkillCard name="RxJs" experience="6 year" img={rxjs} />
          <SkillCard name="Redux" experience="3 year" img={redux} />
          <SkillCard
            name="Tailwind Css"
            experience="1 year"
            img={tailwindcss}
          />
          <SkillCard name="Jest" experience="3 year" img={jest} />
          <SkillCard name="Git" experience="6 year" img={git} />
          <SkillCard name="Github" experience="5 year" img={github} />
          <SkillCard name="Azure" experience="3 year" img={azure} />
        </div>
      </div>
      <div id="projects" className="mb-24" />
    </div>
  );
}
