import React from "react";
import Typewriter from "typewriter-effect";

function Text() {
  return (
    <Typewriter
      options={{
        strings: [
          "Senior Front-End Developer",
          "Web Application Expert",
          "Angular Expert",
          "React Enthusiast",
          "UX Designer",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Text;
