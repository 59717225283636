import ProjectCard from "./ProjectCard.js";
import fedex from "../assets/projects/fedex.svg";
import rabobank from "../assets/projects/rabobank.svg";
import alliander from "../assets/projects/alliander.svg";
import garmin from "../assets/projects/garmin.svg";

export default function Experience() {
  return (
    <div className="mt-4 mb-16 text-white">
      <h1 className="text-center text-5xl font-bold mb-16">Projects</h1>
      <div class="grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-3">
        <ProjectCard
          img={fedex}
          name="FedEx"
          year="2023 - present"
          position="Senior Frontend Angular Developer"
          desc="Led as the Senior Frontend Developer in a high-performing team, contributing to the core features of a web application for international shipments and freight management within the logistics and delivery sector worldwide. The application garnered extensive usage across 100+ countries with an active user base exceeding 2 million per week. Utilized the latest versions of the Angular framework (v.18) "
        />
        <ProjectCard
          img={rabobank}
          name="Rabobank"
          year="2018 - 2023"
          position="Senior Frontend Developer, DevOps Engineer"
          desc="Senior Frontend member of an agile team responsible for building web and mobile apps for the domain named: “development of capital” which covers payments, savings and investments inside Rabobank, using web tools like Ionic, Angular, React, Stencil JS, Graphql."
        />
        <ProjectCard
          img={alliander}
          name="Alliander"
          year="2017 - 2018"
          position="GIS software engineer, Medior Frontend developer"
          desc="Designed and built a critical web portal to plot electrical infrastructure of the Netherlands to solve incidents in real time, as a GIS (Geographic Information Systems) engineer, with tools like ESRI, Geocortex and Oracle DB."
        />
        <ProjectCard
          img={garmin}
          name="Garmin - Tacx"
          year="2017 - 2018"
          position="Medior Frontend Developer, Android/iOS mobile Developer"
          desc="Participated in designing and implementing web portals for the customer care department inside Tacx company (now Garmin) using tools such as: AngularJS, Angular, Bootstrap, Ionic, NodeJS."
        />
      </div>
    </div>
  );
}
