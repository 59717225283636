export default function ProjectCard(props) {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="100"
      className="w-full bg-slate-600 rounded-md py-4 px-4"
    >
      <img
        src={props.img}
        className="w-40 max-h-20 mx-auto"
        alt={props.name}
      ></img>
      <div className="mt-2">
        <h1 className="font-bold md:text-xl">{props.name}</h1>
        <p className="text-rose-300 font-light md:text-lg">{props.year}</p>
        <p className="font-light md:text-lg">{props.position}</p>
        <p className="font-light text-gray-400">{props.desc}</p>
      </div>
    </div>
  );
}
